<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

        </b-link>

        <b-card-title class="mb-1">
          Подтверждение логина
        </b-card-title>
        <b-card-text class="mb-2">
          Введите код.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label="Код"
              label-for="code"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="code"
                  :state="errors.length > 0 ? false:null"
                  :disabled="loading"
                  name="code"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              type="submit"
              variant="primary"
              :disabled="loading"
              block
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-spinner
                  v-if="loading"
                  small
                  class="mr-50"
                />
                <span>Подтвердить</span>
              </div>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Вход
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup,
  BFormInput, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      code: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.loading = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt.verifyLogin({
            code: this.code,
          }).then(response => {
            /* eslint-disable */
            const { access_token, refresh_token, userData } = response.data

            if (access_token) {
              useJwt.setToken(access_token)
              useJwt.setRefreshToken(refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$store.commit('app/SET_USER', userData)
              this.$store.commit('app/SET_LOGGED_IN', true)

              window.location.replace('/')
            }
            this.loading = false
          }).catch(error => {
            this.$swal({
              icon: 'error',
              html: error.response.data.error,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })

            this.$refs.simpleRules.setErrors(error.response.data.error)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
